<template>
  <div>
    <div class="row">
      <div class="col-sm-8 offset-sm-2">
        <div class="row">
          <h1 class="col-sm-12">Dictamen inmueble</h1>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <p>Indica si en el dictamen del inmueble se encontraron incidencias</p>
          </div>
        </div>

        <div class="row mb-4">
          <label for="" class="col-form-label col-sm-3">Se encontraron incidencias</label>
          <div class="col-sm-9">
            <BpbRadio v-model="existe_incidencias" :other="{display: 'inline'}" value="1" name="incidencias" label="Sí" />
            <BpbRadio v-model="existe_incidencias" :other="{display: 'inline'}"  value="0" name="incidencias" label="No" />
          </div>
        </div>

        <template v-if="existe_incidencias==1">
        <div class="row mb-2">
          <div class="col-sm-12 text-right"><button class="btn btn-outline-secondary" @click="modal_incidencia_nueva=true">Agregar incidencia</button></div>
        </div>

        <div class="row mb-4">
          <div class="col-sm-12">
            <table class="lista_incidencias">
              <thead>
                <tr>
                  <th>Tipo de Incidencia</th>
                  <th>Descripción</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(incidencia, index) in incidencias" :key="incidencia.id">
                  <td>{{ obtener_nombre(incidencia.subtipo) }}</td>
                  <td>{{ incidencia.descripcion }}</td>
                  <td>
                    <img class="mr-2" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar incidencia" title="Editar incidencia" @click="editar_incidencia(index, incidencia)" />
                    <img src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar incidencia" title="Elimitar incidencia" @click="eliminar_incidencia(index)" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        </template>

        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-warning mr-2" @click="$emit('retroceder')">Retroceder etapa</button>
            <button v-if="existe_incidencias == 1" class="btn btn-info mr-2" @click="guardar_cambios">Guardar cambios</button>
            <button class="btn btn-success mr-2" @click="avanzar_etapa">Avanzar</button>
          </div>
        </div>
      </div>
    </div>

    <Incidencia v-if="modal_incidencia_nueva" :solicitud="solicitud" :incidencia="incidencia" :catalogo="catalogo" @agregar="agregar_incidencia" @close="cancelar_incidencia" />
  </div>
</template>

<script type="text/javascript">
  import BpbRadio from '@/components/Form/Radio'
  import Incidencia from '@/apps/formalizacion/Componentes/Incidencia'

  import apiSolicitud from '@/apps/formalizacion/api/v2/solicitudes'
  import apiCatalogo from '@/apps/formalizacion/api/v2/catalogos'

  export default {
    components: {
      BpbRadio, Incidencia
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            id: null
          }
        }
      }
    }
    ,data() {
      return {
        existe_incidencias: 0
        ,modal_incidencia_nueva: false
        ,catalogo: {
          opciones: []
        }
        ,incidencias: []
        ,incidencia: {
          id: null
          ,subtipo: null
          ,descripcion: null
        }
        ,incidencias_eliminadas: []
      }
    }
    ,mounted() {
      this.obtener_catalogo();
      this.cargarIncidenciasSolicitud()
    }
    ,methods: {
      async obtener_catalogo() {
        this.catalogo = await this.$helper.cache_store('formalizacion.catalogos.incidencias_inmueble',async () => {
          return (await apiCatalogo.buscar_catalogo('incidencias_inmueble')).data
        });
      }
      ,obtener_nombre(subtipo) {
        let nombre;

        this.catalogo.opciones.forEach(opcion => {
          if (opcion.valor == subtipo)
            nombre = opcion.nombre;
        })

        return nombre;
      }
      ,cancelar_incidencia() {
        this.incidencia = {
          id: null
          ,subtipo: null
          ,descripcion: null
        }
        this.modal_incidencia_nueva = false;
      }
      ,agregar_incidencia(incidencia) {
        if (typeof incidencia.index == 'undefined')
          this.incidencias.push(incidencia);
        else {
          for(let i=0; i<this.incidencias.length; i++) {
            if (i == incidencia.index) {
              this.incidencias[i] = incidencia;
            }
          }
        }

        this.incidencia = {
          id: null
          ,subtipo: null
          ,descripcion: null
        }
        this.modal_incidencia_nueva = false;
      }
      ,async guardar_cambios() {
        try {
          let payload = {
            incidencias_tipo: 'dictamen_inmueble'
            ,incidencias: this.nuevas_incidencias
            ,incidencias_eliminar: []
          }


          if (this.incidencias_eliminadas.length > 0)
            this.incidencias_eliminadas.forEach(incidencia => {
              payload.incidencias_eliminar.push(incidencia);
            })

          if (payload.incidencias.length == 0 && payload.incidencias_eliminar.length == 0)
            return this.$helper.showMessage('Error','No hay cambios para guardar','error','alert');

          let res = (await apiSolicitud.incidencias_sincronizar(this.solicitud.id, payload)).data;
          this.$log.info('res', res);

          if (res.errores.length > 0){
            let error = 'Se encontraron los siguientes errores: <ul>'
            res.errores.forEach(tmp_error => {
              error = error + '<li>'+tmp_error+'</li>';
            })
            error = error + '</ul>';
            return this.$helper.showMessage('Error',error, 'error','alert');
          }

          this.$helper.showMessage('Echo!','Se sincronizaron las incidencias','success','alert');
          if (payload.incidencias_eliminar.length > 0){
            this.incidencias_eliminadas = [];
          }

          this.$emit('update')
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,editar_incidencia(index, incidencia) {
        incidencia.index = index;
        this.incidencia = incidencia;
        this.modal_incidencia_nueva = true;
      }
      ,eliminar_incidencia(index) {
        let incidencias = [];
        for(let i=0; i<this.incidencias.length; i++) {
          if (i != index)
            incidencias.push(this.incidencias[i]);
          else
            this.incidencias_eliminadas.push(this.incidencias[i]);
        }

        this.incidencias = incidencias;
      }
      ,avanzar_etapa() {

        const nuevas_incidencias = this.nuevas_incidencias;
        console.log('nuevas incidencias', nuevas_incidencias)
        if (nuevas_incidencias.length > 0 || this.incidencias_eliminadas.length > 0 && success) {
          this.guardar_cambios();
        }

        if (this.existe_incidencias == 1) {
          if (this.incidencias.length === 0) {
           return this.$helper.showMessage('Error', 'Si seleccionó que si se encontraron incidencias, debe agregar al menos una incidencia para poder avanzar', 'error', 'alert');
          }
        }
        console.log('avanzar')
        this.$emit('avanzar');
      }
      ,cargarIncidenciasSolicitud(){
        let incidencias = this.incidencias_validas;
        if (incidencias.length > 0) {
          this.existe_incidencias = 1;
          this.incidencias = incidencias;
        }
      }
    }
    ,computed: {
      incidencias_validas() {
        let incidencias = [];

        this.solicitud.incidencias.forEach(incidencia => {
          if (incidencia.tipo == 'dictamen_inmueble')
            incidencias.push(incidencia)
        })

        return incidencias;
      }
      ,nuevas_incidencias(){
        let nuevas_incidencias = [];
        this.incidencias.forEach(incidencia => {
          if (!incidencia.id)
            nuevas_incidencias.push(incidencia);
          else if(incidencia.index)
            nuevas_incidencias.push(incidencia);
        })
        return nuevas_incidencias;
      }
    }
    ,watch: {
      solicitud: {
        handler() {
          console.log('cargar incidencias')
          this.cargarIncidenciasSolicitud();
        }
        , deep: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .lista_incidencias {
    width: 100%;

    thead {
      tr {
        th {
          background-color: #4C4C4C;
          color: #fff;
          padding: 5px 10px;
        }
      }
    }

    tbody {
      tr {
        // border-bottom: solid 1px #E6E6E6;

        td {
          padding: 5px 10px;
          img {
            width: 20px;
          }
        }

        td:nth-child(3) {
          text-align: center;
        }
      }

      tr:nth-child(2n+0) {
        background-color: #E6E6E6;
      }
    }
  }
</style>